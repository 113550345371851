import user from "./user"
import vehicules from "./vehicules";
import vehicule from "./vehicule";
import manufacturers from "./manufacturers";
import modelseries from "./modelseries";
import motorisations from './motorisations';
import clients from './clients';
import client from './client';
import settings from './settings';
import factures from './factures';
import facture from './facture';
import codesPostaux from "./codesPostaux";
import produits from "./produits";
import produit from "./produit";
import services from "./services";
import service from "./service";
import devis from "./devis";
import devi from "./devi";
import changelog from "./changelog";
import catalogue from "./catalogue";
import scheduler from "./scheduler";

export default {
    user,
    vehicules,
    vehicule,
    manufacturers,
    modelseries,
    motorisations,
    clients,
    client,
    settings,
    factures,
    facture,
    codesPostaux,
    produits,
    produit,
    services,
    service,
    devis,
    devi,
    changelog,
    catalogue,
    scheduler
}