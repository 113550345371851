import React, { useEffect, useState } from 'react';
import Modal from '../Modal';

import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import actions from '../../actions';
import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';

function ChangeLogModal(props) {
    const intl = props.intl;
    const changelog = props.changelog || {};

    return <Modal display={true} >
        <Paper elevation={0}>
            {Object.keys(changelog).map((key) => {
                let _changeLog = changelog[key];

                return <div>
                    <Typography variant="h6" gutterBottom component="div">
                        <b>Version : {key}</b>
                    </Typography>
                    <Divider/>
                    <br />
                    {_changeLog.map((line) => {
                        return <div>{line || <br/>}</div>;
                    })}
                    <br/>
                </div>


            })}
            <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                <Grid item xs={16} sx={{ textAlign: 'center' }}>
                    <Button
                        variant="outlined"
                        sx={{ width: '100%' }}
                        onClick={() => {
                            props.onClose && props.onClose();
                        }}
                    >{intl.formatMessage({ id: 'button.validate' })}</Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>;
}

export default withSnackBar(withStoreProvider(injectIntl(ChangeLogModal)));