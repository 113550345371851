import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import actions from '../../actions';
import Loader from '../../components/Loader';

function CatalogSelectCategorie(props) {

    const categories = props.categories || [];

    const [displayLoader, setDisplayLoader] = useState(false);
    
    function generateTree(cat) {
        return cat.map((_cat, _idx) => {
            return <TreeItem disabled={_cat.products.length == 0} itemId={"grid-" + _idx} label={_cat.name} onClick={() => {
                props.onSelect && props.onSelect(_cat);
            }}>
            </TreeItem>
        })
    }
        
    return <span>
        <Loader display={displayLoader} />
        <SimpleTreeView>
            {generateTree(categories)}
        </SimpleTreeView>
    </span>;
}

export default withNavigation(withStoreProvider(withSnackBar(injectIntl(CatalogSelectCategorie))));