import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import Box from '@mui/material/Box';
import { Scheduler } from "@aldabil/react-scheduler";

import actions from '../../actions';
import locale from '../../locales';

function SchedulPage(props) {
    const intl = props.intl;

    const [displayLoader, setDisplayLoader] = useState(false);
    const [events, setEvents] = useState([]);

    async function fetchData(loader = false) {
        setDisplayLoader(loader);
        try {
            let result = await props.dispatch(actions.scheduler.getAll());
            setEvents(result.scheduler.filter(el => ((el.deleted !== 1) && (el.deleted !== true))));
        } catch (err) {
            props.snackbar.error(err.message);
        }
        setDisplayLoader(false);
    }

    useEffect(() => {
        fetchData(true);
    }, []);

    return <Box>
        <Scheduler
            hourFormat={24}
            deletable={true}
            editable={true}
            draggable={true}
            events={events}
            locale={locale.getLocale("fr")}
            translations={
                {
                    navigation: {
                        month: "Mois",
                        week: "Semaine",
                        day: "Jour",
                        today: "Aujourd'hui",
                        agenda: "Agenda"
                    },
                    form: {
                        addTitle: "Ajouter un rendez-vous :",
                        editTitle: "Modifier un rendez-vous :",
                        delete: "Supprimer",
                        cancel: "Annuler",
                        confirm: "Valider",
                    },
                    event: {
                        title: "Titre",
                        subtitle: "Commentaire",
                        start: "Date et heure du rendez-vous",
                        end: "Fin du rendez-vous",
                        allDay: "Tous les jours"
                    },
                    validation: {
                        required: "Requis",
                        invalidEmail: "Email invalide",
                        onlyNumbers : "Uniquement des chiffres",
                        min: "Minimum {{min}} lettres",
                        max: "Maximum {{max}} lettres"
                    },
                    moreEvents: "plus...",
                    noDataToDisplay: "Pas de données a affichée",
                    loading: "Chargement..."
                }
            }
            onDelete={async (id) => {
                try {
                    await props.dispatch(actions.scheduler.delete(id));
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
            onConfirm={async (event, action) => {
                try {
                    switch (action) {
                        case "create":
                            await props.dispatch(actions.scheduler.create(event));
                            break;
                        case "edit":
                            await props.dispatch(actions.scheduler.update(event));
                            break;
                    }
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
            onEventDrop={async (event, droppedOn, updatedEvent, originalEvent) => {
                try {
                    await props.dispatch(actions.scheduler.update(updatedEvent));
                    await fetchData();
                } catch (err) {
                    props.snackbar.error(err.message);
                }
            }}
        />
    </Box>
}

export default withStoreProvider(withSnackBar(injectIntl(SchedulPage)));