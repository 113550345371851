import createAction from '../../middleware/actions';


export default {
    create: createAction(async (facture = {}, { extra }) => {
        try {
            let result = await extra.api.post("/api/v1/private/facture", facture);
            return { facture: result.facture }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getById: createAction(async (id = "", { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/facture", { query: { id: id } });
            return { facture: result.facture}
        } catch (err) {
            throw { message: err.message };
        }
    }),
    printPDF: createAction(async (facture = {}, { extra, getState }) => {
        try {
            let result = await extra.pdf.facture(facture, { extra, getState });
            return {}
        }
        catch (err) {
            throw { message: err.message };
        }
    }),
}