import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import routeMdw from './middleware/route';

import HomePage from './pages/home';
import LoginPage from './pages/login';
import VehiculesPage from './pages/vehicules';
import ClientsPage from './pages/clients';
import SettingsPage from './pages/settings';
import SignupPage from './pages/signup';
import ForgotPasswordPage from './pages/forgotPassword';
import ProduitsPage from './pages/produits';
import ServicesPage from './pages/services';
import DevisPage from './pages/devis';
import DevisCreatePage from './pages/devisCreate';
import DevisDisplayPage from './pages/devisDisplay';
import FacturesPage from './pages/factures';
import FacturesDisplayPage from './pages/facturesDisplay';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import CataloguePage from './pages/catalogue';
import InstallPage from './pages/install';
import SchedulPage from './pages/schedul';

const routes = [
    { path: routeMdw.urlIndex(), name: 'LoginPage', Component: <InstallPage /> },
    { path: routeMdw.urlLogin(), name: 'LoginPage', Component: <LoginPage /> },
    { path: routeMdw.urlHome(), name: 'HomePage', Component: <HomePage /> },
    { path: routeMdw.urlVehicules(), name: 'VehiculesPage', Component: <VehiculesPage /> },
    { path: routeMdw.urlClients(), name: 'ClientsPage', Component: <ClientsPage /> },
    { path: routeMdw.urlSettings(), name: 'SettingsPage', Component: <SettingsPage /> },
    { path: routeMdw.urlSignup(), name: 'SignupPage', Component: < SignupPage /> },
    { path: routeMdw.urlForgotPassword(), name: 'ForgotPasswordPage', Component: <ForgotPasswordPage /> },
    { path: routeMdw.urlProduits(), name: 'ProduitsPage', Component: <ProduitsPage /> },
    { path: routeMdw.urlServices(), name: 'ServicesPage', Component: <ServicesPage /> },
    { path: routeMdw.urlDevis(), name: 'DevisPage', Component: <DevisPage /> },
    { path: routeMdw.urlBillings(), name: 'FacturesPage', Component: <FacturesPage /> },
    { path: routeMdw.urlBillingDisplay(':id'), name: 'FacturesDisplayPage', Component: <FacturesDisplayPage /> },
    { path: routeMdw.urlDevisCreate(), name: 'DevisCreatePage', Component: <DevisCreatePage /> },
    { path: routeMdw.urlDevisEdit(':id'), name: 'DevisEditPage', Component: <DevisCreatePage /> },
    { path: routeMdw.urlDevisDisplay(':id'), name: 'DevisDisplayPage', Component: <DevisDisplayPage /> },
    { path: routeMdw.urlCatalog(), name: 'CataloguePage', Component: <CataloguePage /> },
    { path: routeMdw.urlPlanning(), name: 'SchedulPage', Component: <SchedulPage /> }
];

function App(props) {

    const intl = props.intl;
    const selectedVehicule = props.globalState.selectedVehicule;

    const [drawerState, setDrawerState] = useState(false);


    return <Box >
        <AppBar onClick={() => { props.globalState.user && setDrawerState(true) }} title={(selectedVehicule?.plate && selectedVehicule?.designation) ? selectedVehicule?.plate + " : " + selectedVehicule?.designation : undefined} />
        <Box sx={{ paddingTop: '64px' }} >
            <Container maxWidth="xl" sx={{ paddingTop: "25px" }} >
                <Drawer
                    open={drawerState}
                    onClose={() => { setDrawerState(false) }}
                />
                <Routes >
                    {routes.map(({ path, Component }) => (
                        <Route path={path} key={path} element={Component} />
                    ))}
                </Routes>
            </Container>
        </Box>
    </Box>
}

export default withNavigation(withStoreProvider(injectIntl(App)));