import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import actions from '../../actions';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import Loader from '../../components/Loader';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import routeMdw from '../../middleware/route';

const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

function ForgotPasswordPage(props) {

    const intl = props.intl;

    const [displayLoader, setDisplayLoader] = useState(false);

    return <Box>

        <Loader display={displayLoader} />

        <Box sx={{
            position: 'absolute',
            left: '50%',
            top: '40%',
            width: "80%",
            transform: 'translate(-50%, -40%)',
            textAlign: 'center'
        }}>

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', textAlign: 'center' }}>
                    <LockOutlinedIcon />
                </Avatar>
            </Box>
            <Grid container spacing={2} sx={{ paddingBottom: '10px' }}>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                <Grid item xs={10} sm={8} lg={4} sx={{ textAlign: 'center' }}>
                    <Typography component="h1" variant="h5">
                        {intl.formatMessage({ id: 'forgot.password.title' })}
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
            </Grid>
            <Formik
                initialValues={{ email: '', password: '', username: '' }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                    setDisplayLoader(true);
                    try {
                        let result = await props.dispatch(actions.user.forgotPassword(values));
                    } catch (err) {
                        props.snackbar.error(intl.formatMessage({ id: err.message }));
                    } finally {
                        setDisplayLoader(false);
                    }
                }}
            >
                {({ errors, touched }) => {
                    return <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                            <Grid item xs={10} sm={8} lg={4} sx={{ textAlign: 'center' }}>
                                <Field name="email" error={errors.email && touched.email} type="email" label={intl.formatMessage({ id: 'forgot.password.email' })} as={TextField} sx={{ width: "100%", textAlign: "center" }} variant="outlined" />
                                <ErrorMessage name="email">{msg => props.snackbar.error(intl.formatMessage({ id: msg }))}</ErrorMessage>
                            </Grid>
                            <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                            <Grid item xs={10} sm={8} lg={4} sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth >{intl.formatMessage({ id: 'button.validate' })}</Button>
                            </Grid>
                            <Grid item xs={1} sm={2} lg={4} sx={{ textAlign: 'center' }} />
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Box>
    </Box >
}

export default withNavigation(withStoreProvider(withSnackBar(injectIntl(ForgotPasswordPage))));