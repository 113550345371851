import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withNavigation } from '@remyar/react-navigation';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';

import actions from '../../actions';
import routeMdw from '../../middleware/route';
import Loader from '../../components/Loader';
import utils from '../../utils';

function InstallPage(props) {

    const intl = props.intl;

    async function fetchData() {
        try {
            let response = await props.dispatch(actions.user.getSession());
            if (response.message) {
                props.snackbar.info(intl.formatMessage({ id: response.message }));
            }
            await props.dispatch(actions.codesPostaux.getAll());
            await props.dispatch(actions.settings.getAll());
            props.navigation.push(routeMdw.urlHome());
        } catch (err) {
            if ( err.message == utils.getCause()){
                props.snackbar.error(intl.formatMessage({ id: 'lost.session' }));
            }
            await props.dispatch(actions.codesPostaux.getAll());
            props.navigation.push(routeMdw.urlLogin());
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    return <Loader display={true} />;
}

export default withStoreProvider(withNavigation(withSnackBar(injectIntl(InstallPage))));