import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/scheduler");
            result.scheduler.forEach((e) => {
                e.start = new Date(e.start);
                e.end = new Date(e.end);

                e.event_id = e.id;
            });
            return { scheduler: result.scheduler }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    create: createAction(async (scheduler = {}, { extra }) => {
        try {
            scheduler.deletable = true;
            scheduler.editable = true;
            scheduler.draggable = true;
            let result = await extra.api.post("/api/v1/private/scheduler", scheduler);
            return { scheduler: result.scheduler }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    delete: createAction(async (id, { extra }) => {
        try {
            let result = await extra.api.del("/api/v1/private/scheduler", { id });
        } catch (err) {
            throw { message: err.message };
        }
    }),
    update: createAction(async (scheduler = {}, { extra }) => {
        try {

            scheduler.id = scheduler.event_id;
            scheduler.deletable = true;
            scheduler.editable = true;
            scheduler.draggable = true;
            
            let result = await extra.api.post("/api/v1/private/scheduler", scheduler);
            return { scheduler: result.scheduler }
        } catch (err) {
            throw { message: err.message };
        }
    }),
}