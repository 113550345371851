import createAction from '../../middleware/actions';

export default {
    getAll: createAction(async ({ extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/devis");
            result.devis = result.devis.map((el) => {
                let total = 0;
                el.products.forEach((product)=>{
                    if (product.reduction && (parseFloat(product.reduction) > 0.0)) {
                        total += parseFloat(product.quantity.toString()) * (parseFloat(product.taux.toString()) - (parseFloat(product.taux.toString()) * (parseFloat(product.reduction) / 100.0)));
                    } else {
                        total += parseFloat(product.taux.toString()) * parseFloat(product.quantity.toString());
                    }
                });

                return { ...el, total: total }
            })
            return { devis: result.devis }
        } catch (err) {
            throw { message: err.message };
        }
    })
}