import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import actions from '../../actions';

function Step3Modal(props) {

    const intl = props.intl;
    const globalState = props.globalState;

    const inputRef = useRef(null);

    const [displayLoader, setDisplayLoader] = useState(false);

    const handleFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        const reader = new FileReader();

        reader.onload = async function (e) {
            const text = e.target.result;
            await props.dispatch(actions.settings.saveLogo(e.target.result));
            setDisplayLoader(false);
        };

        reader.onerror = (err) => {
            console.error(err);
        }

        reader.readAsDataURL(fileObj);
    }

    return <div>
        <br />
        <ListItem disablePadding>
            <Typography variant="h6" gutterBottom component="div">{intl.formatMessage({ id: 'settings.logo.title' })}</Typography>
        </ListItem>
        <Divider />
        <ListItem>
            <Grid container spacing={2}>
                <Grid item xs={2} />
                <Grid item xs={8} sx={{ textAlign: 'center' }}>
                    {globalState?.settings?.logo && <img src={/*'data:image/png;base64,' +*/ globalState?.settings?.logo} width={305 / 2} height={140 / 2} />}
                    {!globalState?.settings?.logo && <Typography variant="h8" gutterBottom component="div">{intl.formatMessage({ id: 'settings.logo.no' })}</Typography>}
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </ListItem>
        <ListItem>
            <Grid container spacing={2}>
                <Grid item xs={2} />
                <Grid item xs={8} sx={{ textAlign: 'center' }}>
                    <Stack direction="row" spacing={2} sx={{ display: 'block' }}>

                        {/*
                        <Button variant="contained" onClick={async () => {
                            try {
                                setDisplayLoader(true);
                                const filename = (await props.dispatch(actions.electron.getFilenameForOpen('.png')))?.getFilenameForOpen;
                                if (filename.canceled == false) {
                                    let fileData = (await props.dispatch(actions.electron.readPng(filename.filePath)))?.fileData;
                                    await props.dispatch(actions.set.saveEntrepriseLogo(new Buffer(fileData).toString('base64')));
                                }
                            } catch (err) {
                                props.snackbar.error(err.message);
                            } finally {
                                setDisplayLoader(false);
                            }
                        }}>
                            {intl.formatMessage({ id: 'settings.logo.select' })}
                    </Button>*/}
                        <input
                            style={{ display: 'none' }}
                            ref={inputRef}
                            type="file"
                            onChange={handleFileChange}
                        />
                        <Button variant="contained" onClick={async () => {
                            try {
                                setDisplayLoader(true);
                                // 👇️ open file input box on click of another element
                                inputRef.current.click();
                            } catch (err) {
                                props.snackbar.error(err.message);
                            }
                        }}>
                            {intl.formatMessage({ id: 'settings.logo.select' })}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={2} >
                    <DeleteForeverIcon sx={{ marginTop: "5px", cursor: "pointer" }} onClick={() => {
                        props.dispatch(actions.settings.saveLogo("".toString('base64')));
                    }} />
                </Grid>
            </Grid>
        </ListItem>
        <br />
    </div>
}

export default withSnackBar(withStoreProvider(injectIntl(Step3Modal)));