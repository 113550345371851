import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Loader from '../../components/Loader';
import Wizard from '../../components/Wizard';

import actions from '../../actions';

import CAChart from '../../components/CAChart';
import ChangeLogModal from '../../components/ChangeLogModal';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import DatePicker from '../../components/DatePicker';
import packageJson from '../../../package.json';

const localizer = momentLocalizer(moment) // or globalizeLocalizer

require("react-big-calendar/lib/css/react-big-calendar.css")

function HomePage(props) {

    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayWizard, setDisplayWizard] = useState(false);
    const [displayChangeLog, setDisplayChangeLog] = useState(false);
    const [factures, setFactures] = useState([]);
    const [yearSelected, setYearSelected] = useState(new Date());
    const [changelog, setChangelog] = useState({});

    const intl = props.intl;
    const vehicule = props.globalState.vehicule || {};
    const settings = props.globalState.settings || {};

    if ((settings?.wizard == undefined) || (settings?.wizard == true)) {
        //-- lancement du wizard
        if (displayWizard == false) {
            setDisplayWizard(true);
        }
    }
    /*
        if (settings?.version == undefined ){
            if (displayChangeLog == false && displayWizard == false){
                setDisplayChangeLog(true);
            }
        }
    */
    async function discardWizard() {
        await props.dispatch(actions.settings.save({ wizard: false }));
        setDisplayWizard(false);
    }

    async function discardChangeLog() {
        await props.dispatch(actions.settings.save({ version: packageJson.version }));
        setDisplayChangeLog(false);
    }

    async function fetchData() {
        setDisplayLoader(true);
        try {
            let result = await props.dispatch(actions.factures.getAll());
            setFactures(result.factures);

            let _changelog = (await props.dispatch(actions.changelog.getAll())).changelog;
            let keys = [];
            /* Object.keys(_changelog).map((key) => {
                 keys.push(key);
             });*/

            keys = keys.sort().filter((e) => e > (settings?.version || packageJson.version));

            let obj = undefined;
            Object.keys(_changelog).map((key) => {
                if (keys.find((e) => e == key) != undefined) {
                    if (obj == undefined) {
                        obj = {};
                    }
                    obj[key] = _changelog[key];
                }
            });
            if (obj != undefined) {
                setDisplayChangeLog(true);
            } else {
                await discardChangeLog();
            }
            setChangelog(obj);

        } catch (err) {
            props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
        } finally {
            setDisplayLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    let ca = {};
    let caTotal = 0;

    factures?.forEach((_f) => {
        let year = new Date(_f.date).getFullYear();
        let month = new Date(_f.date).getMonth();

        if (year == yearSelected.getFullYear()) {

            if (ca[year] == undefined) {
                ca[year] = {
                    service: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    product: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            }

            _f?.products?.forEach((_p) => {
                caTotal += (parseFloat(_p.taux || 0) * parseFloat(_p.quantity || 0));
                let __r = 0;
                if (_p.reduction) {
                    __r = (parseFloat(_p.taux) * parseFloat(_p.reduction) / 100.0);
                }

                caTotal-= __r;

                if (_p.isService == true) {

                    let _r = (parseFloat(_p.taux || 0) * parseFloat(_p.quantity || 0));
                    _r -= __r;

                    ca[year].service[month] += parseFloat(_r.toFixed(2));
                } else {
                    let _r = (parseFloat(_p.taux || 0) * parseFloat(_p.quantity || 0));
                    _r -= __r;
                    ca[year].product[month] += parseFloat(_r.toFixed(2));
                }
            });
        }
    });


    return <Box>

        <Loader display={displayLoader} />

        {displayChangeLog && <ChangeLogModal onClose={discardChangeLog} changelog={changelog} />}

        {displayWizard && <Wizard onClose={discardWizard} />}

        <Box sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            width: "80%",
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        }}>

            <Grid container spacing={2}>
                <Grid item xs={1} sm={2} lg={3} sx={{ textAlign: 'center' }} />
                <Grid xs={10} sm={8} lg={6} sx={{ textAlign: 'center' }}>
                    <CAChart data={ca[yearSelected.getFullYear()]} title={"CA " + yearSelected.getFullYear() + " - " + caTotal.toFixed(2) + "€"} />
                </Grid>
                <Grid item xs={1} sm={2} lg={3} sx={{ textAlign: 'center' }} />
            </Grid>
            <br />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={1} sm={2} lg={3} sx={{ textAlign: 'center' }} />
                <Grid item xs={10} sm={8} lg={6} sx={{ textAlign: 'center' }}>
                    <DatePicker
                        value={yearSelected}
                        sx={{ width: "100%" }}
                        views={['year']}
                        title="Année"
                        onChange={(value) => {
                            setYearSelected(value);
                        }}
                    />
                </Grid>
                <Grid item xs={1} sm={2} lg={3} sx={{ textAlign: 'center' }} />
            </Grid>
        </Box>
    </Box >

}

export default withStoreProvider(withSnackBar(withNavigation(injectIntl(HomePage))));