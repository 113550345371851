import utils from "../utils"

export default function post(url, data, config = {}) {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            };

            let response = await fetch(url, {
                method: "post", 
                body: JSON.stringify(data),
                headers: new Headers(headers)
            });
            if (response.status == 200) {
                let r = undefined;
                if (config && config.responseType && config.responseType == 'arraybuffer') {
                    r = await response.arrayBuffer();
                } else {

                    if (response.headers && response.headers.get('content-type').includes("text/html")) {
                        r = await response.text();
                    } else if (response.headers.get('content-type').includes("application/json")) {
                        r = await response.json();
                    } else {
                        r = await response.json();
                    }
                }
                response = r;
            }

            if (response.status == 200) {
                resolve(response);
            }
            else if (response.status == 302) {
                utils.reload("no.session");
            }
            else {
                reject({ message: response.message });
            }
        } catch (err) {
            reject(err);
        }
    });
}