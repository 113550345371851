import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { MemoryRouter } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import { NavigationProvider } from '@remyar/react-navigation';
import { StoreProvider } from '@remyar/react-store';
import { SnackbarProvider } from '@remyar/react-snackbar';
import App from './App';

import api from "./api";
import pdf from "./pdf";

import localeData from './locales';

import actions from './actions';

require('./utils');

async function startApp() {

    let settings = { wizard : true }
    // settings = await database.getSettings() || {};

    // Define user's language. Different browsers have the user locale defined
    // on different fields on the `navigator` object, so we make sure to account
    // for these different by checking all of them
    const language = (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    window.userLocale = language;

    // Split locales with a region code
    let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    window.userLocaleWithoutRegionCode = languageWithoutRegionCode;
    localeData.setLocale(languageWithoutRegionCode);
    // Try full locale, try locale without region code, fallback to 'en'
    const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.fr;

    const root = createRoot(document.getElementById('root'));

    function LocalizationWrapper() {

        const [locale, setLocale] = useState(languageWithoutRegionCode);

        return <IntlProvider locale={languageWithoutRegionCode} messages={localeData[locale] || localeData[language] || localeData.fr}>
            <SnackbarProvider>
                <App
                    onLocaleChange={(locale) => { setLocale(locale)}}
                />
            </SnackbarProvider>
        </IntlProvider>
    }

    let _settings = {
        tempSettings: {
            godMode: false,
        },
        entreprise: {},
        paiement: {},
        ...(settings || { })
    }

    try{
         actions.user.getSession()
    } catch(err){

    }
    root.render(
        <React.Fragment>
            <GlobalStyles
                styles={{
                    h1: { color: 'grey' },
                    '*::-webkit-scrollbar': {
                        width: '0.4em',
                    },
                    '*::-webkit-scrollbar-track': {
                        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                        outline: '0px solid slategrey',
                    },
                }}
            />
            <CssBaseline />
            <StoreProvider extra={{ api , pdf }} globalState={{
                settings: { installed: false, locale: "fr", ..._settings },
            }}>
                <MemoryRouter>
                    <NavigationProvider>
                       <LocalizationWrapper />
                    </NavigationProvider>
                </MemoryRouter>
            </StoreProvider>
        </React.Fragment>
    );

}

startApp();