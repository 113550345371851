import sleep from './sleep';
import isNaNOrNullOrUndefined from './isNaNOrNullOrUndefined';

Date.isLeapYear = function (year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
    return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
    return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
};

Date.prototype.getFullDate = function () {
    var date = this.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    return date;
}

Date.prototype.getFullMonth = function () {
    var month = this.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    return month;
}

function arrayBufferToString(array, encoding) {
    let blob = new Blob([array], { type: "application/octet-stream" });
    let reader = new FileReader();
    let done = function () { };

    reader.onload = event => {
        done(event.target.result);
    };

    if (encoding) { reader.readAsText(blob, encoding); } else { reader.readAsText(blob); }

    return { done: function (callback) { done = callback; } }
}

export default {
    sleep,
    isNaNOrNullOrUndefined,
    arrayBufferToString,
    reload: (cause) => {
        window.location.href = window.location.origin + (cause ? ("?cause=" + cause) : "");
    },
    getCause : () => {
        return window.location.search?.replace("?","")?.split('&')?.find(e => e.includes("cause"))?.split("=")[1];
    }
}