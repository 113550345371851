import createAction from '../../middleware/actions';

export default {
    getFromPlate: createAction(async (plate, { extra }) => {
        try {
            let result = await extra.api.get("/api/v1/private/vehicule", { query: { plate: plate } });
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    save: createAction(async (vehicule, { extra }) => {
        try { 
            let result = await extra.api.post("/api/v1/private/vehicule", vehicule);
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    }),
    setSelected: createAction(async (vehicule = {}, { extra }) => {
        try {
            return {
                selectedVehicule: { ...vehicule }
            };
        } catch (err) {
            throw { message: err.message };
        }
    }),
    delete: createAction(async (id, { extra }) => {
        try {
            let result = await extra.api.del("/api/v1/private/vehicule", { id });
        } catch (err) {
            throw { message: err.message };
        }
    }),
    getByTecdocId: createAction(async (tecdocid, { extra }) => {
        try {
            let result = await extra.api.get("/api/vehicule", { query: { tecdocid: tecdocid } });
            return { vehicule: result.vehicule }
        } catch (err) {
            throw { message: err.message };
        }
    })
}