import utils from "../utils"

export default function get(url, config = {}) {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-transform',
                // 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36',
                ...config.headers || {}
            };

            if (config.query) {
                url += '?' + (new URLSearchParams(config.query)).toString();
            }
            let response = await fetch(url, {
                method: "get",
                headers: new Headers(headers)
            });
            if (response.status == 200) {
                let r = undefined;
                if (config && config.responseType && config.responseType == 'arraybuffer') {
                    r = await response.arrayBuffer();
                } else {
                    if (response.headers && response.headers.get('content-type').includes("text/html")) {
                        r = await response.json();
                    } else if (response.headers.get('content-type').includes("application/json")) {
                        r = await response.json();
                    } else {
                        r = await response.json();
                    }
                }
                response = r;
            }

            if (response.status == 200) {
                resolve(response);
            }
            else if (response.status == 302) {
                utils.reload("no.session");
            }
            else {
                reject({ message: response.message });
            }
        } catch (err) {
            reject(err);
        }
    });
}