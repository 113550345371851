import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withStoreProvider } from '@remyar/react-store';
import { withSnackBar } from '@remyar/react-snackbar';
import { withNavigation } from '@remyar/react-navigation';

import actions from '../../actions';
import Loader from '../../components/Loader';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import CatalogSelectCategorie from '../../components/CatalogSelectCategorie';

function CataloguePage(props) {
    const intl = props.intl;
    const selectedVehicule = props.globalState.selectedVehicule;

    const [displayLoader, setDisplayLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        async function _fetchData() {
            try {
                setDisplayLoader(true);
                let result = await props.dispatch(actions.catalogue.getAll(selectedVehicule));
/*
                let _cat = result.catalogue.map((e) => {
                    return {
                        ...e.category, products: e.abstractProducts
                    };
                })
*/
                setCategories(result.catalogue);
            } catch (err) {
                props.snackbar.error(intl.formatMessage({ id: 'fetch.error' }));
            } finally {
                setDisplayLoader(false);
            }
        }

        _fetchData();
    }, []);

    function generatePreviewThumbnails(url) {

        if ( url ){
            return <img src={url} alt="..." width="200px" />;
        } else {
            return <img width="200px" src={"/no-image-available.jpg"} alt="..." />
        }

        /*
        let _items = [];

        if (thumbnails?.length > 0) {
            _items = thumbnails?.map((th) => {
                return <img src={"https://webservice.tecalliance.services/pegasus-3-0/documents/" + 20645 + "/" + th.docId + "/1"} alt="..." width="200px" />;
            })
        } else {
            _items.push(<img width="200px" src={"/no-image-available.jpg"} alt="..." />)
        }

        return <AliceCarousel disableButtonsControls disableDotsControls items={_items} />*/
    }

    return <Box>

        <Loader display={displayLoader} />

        <Grid container spacing={2}>
            <Grid size={3} >
                <CatalogSelectCategorie
                    categories={categories}
                    onSelect={(cat) => {
                        setArticles(cat.products)
                    }}
                />
            </Grid>
            <Grid size={9} >
                {articles?.map((article) => {
                    return <Box style={{ border: "solid 1px #e8e8e8", borderRadius: "16px", marginBottom: "15px" }}>
                        <Grid container spacing={2}>
                            <Grid size={3} >
                                {generatePreviewThumbnails(article?.titleImage?.url)}
                            </Grid>
                            <Grid size={8} >
                                <Grid container spacing={2}>
                                    <Grid size={12} >
                                        <Typography variant="h6" gutterBottom component="div">
                                            <b>{article.name}</b> - {article.id}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={1} >

                            </Grid>
                        </Grid>
                    </Box>
                })}
            </Grid>
        </Grid>
    </Box>
}

export default withStoreProvider(withSnackBar(injectIntl(CataloguePage)));